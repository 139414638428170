import React, { Fragment } from 'react';
import { BulkDeleteButton, List, Datagrid, TextField, BooleanField, Pagination, ReferenceField } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const MessageList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
		perPage={50}
	>
		<Datagrid rowClick='edit'>
			<TextField source="id" sortable={true}/>
            <TextField source="email" sortable={true}/>
            <TextField source="topic" sortable={false}/>
            <TextField source="message" sortable={false}/>
			<ReferenceField label="Author" source="user" reference="users" allowEmpty={true}>
				<TextField source="username" />
			</ReferenceField>
		</Datagrid>
	</List>
);

export default MessageList;
