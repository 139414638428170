import React, { Fragment } from 'react';
import { BooleanField, BulkDeleteButton, Datagrid, List, Pagination, TextField } from 'react-admin';

const PostPagination = (props: any) => <Pagination rowsPerPageOptions={[50]} {...props}/>

const BulkActionButtons = (props : any) => (<Fragment>
	<BulkDeleteButton {...props}/>
</Fragment>);

const SuggestionList = (props: any) => (
	<List {...props}
		bulkActionButtons={<BulkActionButtons/>}
		pagination={<PostPagination/>}
		perPage={50}
	>
		<Datagrid rowClick='edit'>
			<TextField source="id" sortable={true}/>
            <TextField source="name" sortable={true} label="Suggested name"/>
            <TextField source="markerEnt.name" sortable={true} label="Original name"/>
            <TextField source="description" sortable={false} label="Suggested description"/>
            <TextField source="markerEnt.description" sortable={false} label="Original description"/>
            <TextField source="categoryEnt.name" sortable={false} label="Suggested Category"/>
            <TextField source="markerEnt.categoryEnt.name" sortable={false} label="Original Category"/>
						<TextField source='owner.username' required label="Suggestion's author"/>
			<BooleanField source='open' />
		</Datagrid>
	</List>
);

export default SuggestionList;
